import React from 'react';
import { ImageBackground, StyleSheet, Pressable } from 'react-native';
import { Layout, Text } from '@ui-kitten/components';
import { FontAwesome } from '@expo/vector-icons';
import BackgroundImageSource from '../david-breuer_it-consultant-jamstack-architect.png';
import VCardSVG from '../components/VCardSVG';
import QRCodeSVG from '../components/QRCodeSVG';

const image = {
  uri: BackgroundImageSource,
};
// import '@expo/match-media';
// import { useMediaQuery } from 'react-responsive';

export const TextDemo = ({ navigation }) => {
  // const isTabletOrMobileDevice = useMediaQuery({
  //   maxDeviceWidth: 991,
  //   // alternatively...
  //   query: '(max-device-width: 991px)',
  // });

  return (
    <Layout style={styles.container}>
      <ImageBackground source={image} style={styles.image} />
      <Pressable
        onPress={() => navigation.navigate('QRcode')}
        style={{
          padding: 10,
          cursor: 'pointer',
          position: 'absolute',
          zIndex: 1,
        }}
      >
        <FontAwesome style={styles.qricon} name="qrcode" color="white" />
      </Pressable>
      <Pressable
        onPress={() => navigation.navigate('VCard')}
        style={{
          padding: 10,
          cursor: 'pointer',
          position: 'absolute',
          left: 60,
          zIndex: 1,
        }}
      >
        <FontAwesome style={styles.qricon} name="address-card" color="white" />
      </Pressable>
      <Layout style={styles.layout}>
        <Text style={styles.lead}>David Breuer</Text>
        <Text style={styles.title}>IT Consulting</Text>
        <Layout style={styles.sections}>
          <FontAwesome style={styles.icon} name="cloud" color="white" />
          <FontAwesome style={styles.icon} name="gamepad" color="white" />
          <FontAwesome style={styles.icon} name="code" color="white" />
          <FontAwesome style={styles.icon} name="bicycle" color="white" />
        </Layout>
      </Layout>
    </Layout>
  );
};

export const QRCode = ({ navigation }) => {
  return (
    <Layout style={styles.container}>
      <Pressable
        onPress={() => navigation.navigate('Home')}
        style={{
          padding: 10,
          cursor: 'pointer',
          position: 'absolute',
          zIndex: 1,
        }}
      >
        <FontAwesome style={styles.qricon} name="home" color="white" />
      </Pressable>
      <Layout style={styles.layout}>
        <QRCodeSVG style={styles.stretch} />
        <Text style={styles.scanText}>Scan Me</Text>
      </Layout>
    </Layout>
  );
};

export const VCardCode = ({ navigation }) => {
  return (
    <Layout style={styles.container}>
      <Pressable
        onPress={() => navigation.navigate('Home')}
        style={{
          padding: 10,
          cursor: 'pointer',
          position: 'absolute',
          zIndex: 1,
        }}
      >
        <FontAwesome style={styles.qricon} name="home" color="white" />
      </Pressable>
      <Layout style={styles.layout}>
        <VCardSVG style={styles.stretch} />
        <Text style={styles.scanText}>Scan Me</Text>
      </Layout>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    flexWrap: 'wrap',
    backgroundColor: 'none',
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    position: 'absolute',
    width: 529,
    height: 480,
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    justifyContent: 'center',
    overflow: 'hidden',
    opacity: 0.3,
  },
  layout: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'none',
  },
  title: {
    fontSize: '8vw',
    textTransform: 'uppercase',
    fontFamily: 'Bangers, cursive',
    fontWeight: '700',
    color: 'var(--text-primary)',
    letterSpacing: 6,
  },
  lead: {
    fontSize: '4vw',
    textTransform: 'uppercase',
    fontFamily: 'Bangers, cursive',
    fontWeight: '700',
    color: 'var(--text-primary)',
    letterSpacing: 6,
  },
  sections: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  icon: {
    flex: 1,
    margin: 10,
    fontSize: 48,
  },
  qricon: {
    flex: 1,
    margin: 10,
    fontSize: 48,
  },
  stretch: {
    width: '90%',
    height: '90%',
    maxWidth: 500,
    maxHeight: 500,
    resizeMode: 'contain',
  },
  scanText: {
    fontSize: 48,
    fontFamily: 'Bangers, cursive',
    color: 'var(--text-primary)',
    backgroundColor: 'var(--background-secondary)',
    width: '90%',
    maxWidth: 500,
    textAlign: 'center',
    padding: 10,
  },
});
