import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { TextDemo, QRCode, VCardCode } from '../screens/Demos';

const MainStack = createStackNavigator();

export const Main = () => (
  <MainStack.Navigator>
    <MainStack.Screen
      name="Home"
      component={TextDemo}
      options={{ headerShown: false }}
    />
    <MainStack.Screen
      name="QRcode"
      component={QRCode}
      options={{ headerShown: false }}
    />
    <MainStack.Screen
      name="VCard"
      component={VCardCode}
      options={{ headerShown: false }}
    />
  </MainStack.Navigator>
);
