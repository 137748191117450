import React from 'react';
import * as eva from '@eva-design/eva';
import { ApplicationProvider } from '@ui-kitten/components';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { Main } from './navigation/Main';
import './global.css';

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'transparent',
  },
};

const linking = {
  prefixes: ['https://davidbreuer.co.uk', 'davidbreuer://'],
  config: {
    screens: {
      Home: '',
      QRcode: 'qrcode',
      VCard: 'vcard',
    },
  },
};

export default function App() {
  return (
    <>
      <NavigationContainer linking={linking} theme={MyTheme}>
        <ApplicationProvider {...eva} theme={eva.light}>
          <Main />
        </ApplicationProvider>
      </NavigationContainer>
    </>
  );
}
